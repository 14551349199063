import React, { useCallback, Fragment, useContext, useMemo, useState, useRef } from "react"
import moment from "moment"
import { Col, Row } from "react-bootstrap"
import { generatePath, useSearchParams } from "react-router-dom"
import { ReactComponent as Arrow } from "../../../assets/images/arrow.svg"
import useViewport from "../../../hooks/useViewport"
import { className } from "../../../helpers/className"
import {
  ConfirmationModalContext,
  ContextMenuContext,
  DataContext,
  DataContextActions,
  QueryContext,
} from "../../../contexts"
import { DayActions, SessionActions } from "../constants"
import { PropsInjector } from "../../PropsInjector"
import { CreateSessionDetailsForm, EditSessionDetailsForm } from "../forms"
import WeekDays from "./week_days"
import ScheduleContextProvider from "../providers/schedule_context_provider"
import ContextMenuProvider, { ContextMenuNodeTypes } from "../../blocks/ContextMenu"
import Dialog from "../../blocks/Dialog"
import {
  bulkDeleteSessionInSchedule,
  bulkUpdateSessionInSchedule,
  createSessionFromSchedule,
  getMonthScheduleDays,
} from "../configurations/domain"
import { Entities } from "../../../constants"
import { formatDateKeepZone } from "../../../helpers/dates"
import { ReactComponent as ReportIcon } from "../../../assets/images/report-icon.svg"
import { ReactComponent as Pencil } from "../../../assets/images/pencil.svg"
import { ReactComponent as Delete } from "../../../assets/images/delete.svg"
import { checkActionExist } from "../helpers"
import { DayTile, SessionTile } from "./tiles"

const daysInLine = 7
const rowsOfDays = 6
const greedSize = daysInLine * rowsOfDays

const modalTitleMap = {
  [DayActions.CreateSession]: "Unplanned session",
  [SessionActions.Update]: "Edit session",
}

const SessionForm = ({ action, session, onHide }) => {
  const { schedule } = useContext(DataContext)
  const maxBillableMin = schedule.max_duration || 0
  switch (action) {
    case DayActions.CreateSession:
      return (
        <CreateSessionDetailsForm
          session={session}
          maxBillableMin={maxBillableMin}
          onHide={onHide}
          hookConfig={createSessionFromSchedule}
        />
      )
    case SessionActions.Update:
      return (
        <EditSessionDetailsForm
          session={session}
          maxBillableMin={maxBillableMin}
          hookConfig={bulkUpdateSessionInSchedule}
          onHide={onHide}
        />
      )
    default:
      return null
  }
}

const FormEditModal = ({ show, onHide, action, session }) => {
  return (
    <Dialog
      open={show}
      onClose={onHide}
      title={`${modalTitleMap[action]} ${moment(session?.started_at).format("ddd, MMMM D")}`}
    >
      <SessionForm action={action} session={session} onHide={onHide} />
    </Dialog>
  )
}

const WeekDaysActions = ({ children }) => {
  const [showModal, setShowModal] = useState(false)
  const [editableDay, setEditableDay] = useState({})

  const onChange = (action, session) => {
    setEditableDay({ action, session })
    setShowModal(true)
  }

  const onHideModal = () => {
    setShowModal(false)
    setTimeout(() => setEditableDay({}), 250)
  }

  const props = useMemo(() => ({ onChange }), [])

  return (
    <>
      <PropsInjector props={props}>{children}</PropsInjector>
      <FormEditModal show={showModal} session={editableDay.session} action={editableDay.action} onHide={onHideModal} />
    </>
  )
}

const EditableDaysMapper = ({ week, sessions, onChange }) => {
  const { openContextMenu } = useContext(ContextMenuContext)
  const { getHookState } = useContext(QueryContext)
  const { request: deleteRequest } = getHookState(bulkDeleteSessionInSchedule)

  const { openConfirmationModal } = useContext(ConfirmationModalContext)

  const openMenu = useCallback(
    (event, session) => {
      const actions = [
        {
          title: "View report",
          icon: <ReportIcon />,
          nodeType: ContextMenuNodeTypes.Link,
          to: generatePath("reports/:report_date", {
            report_date: formatDateKeepZone(session.started_at, "YYYY-MM-DD"),
          }),
        },
      ]

      if (checkActionExist(session.actions, SessionActions.Update)) {
        if (actions.length === 1) actions.push({ nodeType: ContextMenuNodeTypes.Divider })
        actions.push({
          nodeType: ContextMenuNodeTypes.Button,
          title: "Edit this session",
          icon: <Pencil />,
          iconClassName: "ml-n1",
          onClick: () => {
            onChange(SessionActions.Update, session)
          },
        })
      }

      if (checkActionExist(session.actions, SessionActions.Destroy)) {
        if (actions.length === 1) actions.push({ nodeType: ContextMenuNodeTypes.Divider })
        actions.push({
          nodeType: ContextMenuNodeTypes.Button,
          title: "Delete this session",
          icon: <Delete />,
          iconClassName: "ml-n1",
          className: "-danger",
          onClick: () => {
            openConfirmationModal({
              title: "Delete sessions",
              content: "Please, confirm session removing",
              buttonClassName: "btn btn-error",
              onConfirm: async () => {
                await deleteRequest({
                  data: { ids: [session.id] },
                })
              },
            })
          },
        })
      }

      openContextMenu(event, actions)
    },
    [deleteRequest, onChange, openConfirmationModal, openContextMenu]
  )

  return week.map(({ date, actions, session_ids }) => {
    const [sessionId] = session_ids
    const session = sessions[sessionId] || null

    if (!session) {
      return (
        <DayTile
          key={date}
          date={date}
          actions={actions}
          actionsHandlers={{
            [DayActions.CreateSession]: () => onChange(DayActions.CreateSession, { started_at: date }),
          }}
        />
      )
    }
    return <SessionTile key={date} session={session} onClick={event => openMenu(event, session)} />
  })
}

const Week = ({ ...props }) => (
  <Row className="flex-nowrap mt-2 mx-0 justify-content-between">
    <EditableDaysMapper {...props} />
  </Row>
)

const Schedule = () => {
  const timerRef = useRef(null)
  const [scheduleLoading, setScheduleLoading] = useState(false)
  const [, setParams] = useSearchParams()
  const { getHookState } = useContext(QueryContext)
  const { schedule, scheduleId, scheduleDays, scheduleSessions, scheduleDate } = useContext(DataContext)
  const { updateState } = useContext(DataContextActions)
  const { isPhoneViewport } = useViewport()
  const { fact_sessions, max_sessions } = schedule
  const sessions = useMemo(
    () =>
      scheduleSessions.reduce((acc, session) => {
        acc[session.id] = session
        return acc
      }, {}),
    [scheduleSessions]
  )

  const { request: daysRequest } = getHookState(getMonthScheduleDays)

  const weeks = useMemo(() => {
    let index = 0
    const weeks = []
    while (index < greedSize) weeks.push(scheduleDays.slice(index, (index += daysInLine)))
    return weeks
  }, [scheduleDays])

  const [week] = weeks

  const getMonthDays = useCallback(
    date => {
      if (!scheduleLoading) setScheduleLoading(true)
      setParams(params => {
        const param = date.format("YYYY-MM-DD")
        params.set("month", param)
        updateState("scheduleDate", param)
        return params
      })
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(
        () =>
          daysRequest({
            entitiesIds: {
              [Entities.Schedules]: scheduleId,
            },
            params: {
              from: date.clone().startOf("week").format("YYYY-MM-DD"),
              to: date.clone().endOf("month").endOf("week").format("YYYY-MM-DD"),
            },
            onSuccess: () => {
              setScheduleLoading(false)
            },
          }),
        300
      )
    },
    [daysRequest, scheduleId, scheduleLoading, setParams, updateState]
  )

  const changeMonth = useCallback(
    fn => () => {
      const day = moment(scheduleDate)[fn](1, "month")
      getMonthDays(day)
    },
    [scheduleDate, getMonthDays]
  )

  const resetDate = useCallback(() => {
    const day = moment().startOf("month")
    getMonthDays(day)
  }, [getMonthDays])

  const addMonth = useMemo(() => changeMonth("add"), [changeMonth])
  const subtractMonth = useMemo(() => changeMonth("subtract"), [changeMonth])

  const start = useMemo(() => moment(schedule.start_on).startOf("month"), [schedule.start_on])
  const end = useMemo(() => {
    if (!schedule.end_on) return moment(scheduleDate).add(1, "month")
    return moment(schedule.end_on).endOf("month")
  }, [schedule.end_on, scheduleDate])

  const markedSet = useMemo(() => {
    const marked = new Set()
    scheduleSessions.forEach(session => {
      if (session.report_overdue) marked.add(formatDateKeepZone(session.started_at))
    })
    return marked
  }, [scheduleSessions])

  if (!scheduleDate) return null

  return (
    <div className="card p-4">
      <Row className="align-items-center mb-4">
        <Col xs={24} sm="auto" className="flex-grow-1">
          <Row className={className("align-items-center mx-0", isPhoneViewport && "justify-content-center")}>
            <Col xs="auto" className="px-0">
              <h3 className="session-calendar-title">{moment(scheduleDate).format("MMMM YYYY")}</h3>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm="auto" className="flex-grow-0">
          <Row className="flex-shrink-0 flex-nowrap align-items-center justify-content-between">
            {scheduleLoading && !isPhoneViewport && (
              <Col xs="auto" className="mr-2">
                <div className="spinner-border text-primary" />
              </Col>
            )}
            {!isPhoneViewport && !moment(scheduleDate).isSame(moment().startOf("month"), "month") && (
              <Col xs="auto" className="mr-2">
                <button className="btn btn-link" onClick={resetDate}>
                  To current month
                </button>
              </Col>
            )}
            <Col xs="auto">
              <button
                className="btn btn-outline-primary btn-circle -small"
                disabled={start.isSameOrAfter(scheduleDate, "month")}
                onClick={subtractMonth}
              >
                <div className="icon-10-px rotate-180-deg icon-width-8-px">
                  <Arrow />
                </div>
              </button>
            </Col>
            {!scheduleLoading &&
              isPhoneViewport &&
              !moment(scheduleDate).isSame(moment().startOf("month"), "month") && (
                <Col xs="auto">
                  <button className="btn btn-link" onClick={resetDate} disabled={scheduleLoading}>
                    To current month
                  </button>
                </Col>
              )}
            {scheduleLoading && isPhoneViewport && (
              <Col xs="auto" className="mr-2">
                <div className="spinner-border text-primary" />
              </Col>
            )}
            <Col xs="auto">
              <button
                className="btn btn-outline-primary btn-circle -small"
                disabled={end.isSameOrBefore(scheduleDate, "month")}
                onClick={addMonth}
              >
                <div className="icon-10-px icon-width-8-px">
                  <Arrow />
                </div>
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="overflow-x-auto overflow-y-hidden">
          <WeekDays week={week} />
          <ContextMenuProvider>
            <ScheduleContextProvider
              config={{
                short: true,
                showIcon: true,
                month: scheduleDate,
                highlighted: new Set([]),
                marked: markedSet,
              }}
            >
              <WeekDaysActions>
                {weeks.map((week, idx) => (
                  <Week
                    key={idx}
                    week={week}
                    sessions={sessions}
                    outOfLimit={max_sessions !== null && fact_sessions >= max_sessions}
                    start={start}
                    end={end}
                  />
                ))}
              </WeekDaysActions>
            </ScheduleContextProvider>
          </ContextMenuProvider>
        </Col>
      </Row>
    </div>
  )
}

export default Schedule
