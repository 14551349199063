import { useState, useEffect, useCallback } from "react"
import apiClient from "../../common/apiClient"
import { showErrors } from "../../common/alerter"

export const useGetAdminMessageRequest = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState("")

  const request = useCallback(async () => {
    try {
      setLoading(true)
      const { data: result } = await apiClient.get(`/api/tutor/notation/tutor_dashboard`)
      setData(result.body || "")
    } catch (e) {
      showErrors(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    request()
  }, [request])

  return { loading, data }
}

export const useTutorScheduleRequest = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const request = useCallback(async date => {
    const params = `date=${date}`
    try {
      setLoading(true)
      const { data: result } = await apiClient.get(`/api/tutor/calendar/timetable?${params}`)
      setData(result.events || [])
    } catch (e) {
      showErrors(e)
    } finally {
      setLoading(false)
    }
  }, [])

  return { loading, data, request }
}
