import React, { forwardRef, useCallback, useContext, useMemo, useEffect, useRef, useState } from "react"
import moment from "moment"
import { formatDateKeepZone, minutesToHours } from "../../../../helpers/dates"
import { className } from "../../../../helpers/className"
import { MeetingFormatTitles, SessionActions, SessionStatuses, SessionTitles } from "../../constants"
import { ScheduleContext } from "../../../../contexts"
import { buildId } from "../../../../helpers/forms"
import { ReactComponent as Check } from "../../../../assets/images/check1.svg"
import { ReactComponent as Approved } from "../../../../assets/images/approved-icon.svg"
import { ReactComponent as ReportSent } from "../../../../assets/images/report-sent.svg"
import { ReactComponent as NoPerson } from "../../../../assets/images/no-person.svg"
import { ReactComponent as SessionCancelled } from "../../../../assets/images/session-cancelled.svg"
import { ReactComponent as Cross } from "../../../../assets/images/cross.svg"
import { checkActionExist } from "../../helpers"

const SessionContent = ({ meetingFormat, duration, status, children }) => {
  const [hours, minutes] = minutesToHours(duration)
  const contentClassName = useMemo(() => className("session-calendar-day_content", status && `-${status}`), [status])
  return (
    <div className={contentClassName}>
      <span className="session-calendar-day_content-duration">
        {hours}h {minutes}min
      </span>
      <span className="session-calendar-day_content-type">{MeetingFormatTitles[meetingFormat]}</span>
      {children}
    </div>
  )
}

const SessionHeaderIcon = ({ status, marked }) => {
  const icon = useMemo(() => {
    switch (status) {
      case SessionStatuses.Cancelled:
        return <NoPerson />
      case SessionStatuses.CancelledNoShow:
        return <SessionCancelled />
      case SessionStatuses.Finished: {
        return marked ? <ReportSent /> : <Approved />
      }
      default:
        return null
    }
  }, [status, marked])

  const iconWrapperClassName = useMemo(() => {
    let name = ""
    switch (status) {
      case SessionStatuses.Cancelled:
      case SessionStatuses.CancelledNoShow: {
        name = "icon-gray-color"
        break
      }
      case SessionStatuses.Finished: {
        name = "icon-green-color"
        break
      }
    }
    return className("icon-16-px", name)
  }, [status])

  return <div className={iconWrapperClassName}>{icon}</div>
}

const SessionNode = forwardRef(function SessionNode({ children, className: nodeClassName, onClick }, ref) {
  const nodeId = useMemo(buildId, [])
  return typeof onClick === "function" ? (
    <label htmlFor={nodeId} className={className(nodeClassName, "cursor-pointer")}>
      <button ref={ref} id={nodeId} type="button" className="d-none" onClick={onClick} />
      {children}
    </label>
  ) : (
    <div ref={ref} className={nodeClassName}>
      {children}
    </div>
  )
})

const Session = ({ session, className: htmlClass, onClick, children }) => {
  const {
    short = false,
    showIcon = false,
    month,
    highlighted = new Set(),
    marked = new Set(),
  } = useContext(ScheduleContext)
  const { started_on, status, started_at, meeting_format, duration } = session
  const day = useMemo(() => moment(formatDateKeepZone(started_on)), [started_on])
  const isSameMonth = useMemo(() => (month ? day.isSame(moment(month), "month") : true), [day, month])
  const isHighlighted = useMemo(() => highlighted.has(started_on), [highlighted, started_on])
  const isMarked = useMemo(() => marked.has(started_on), [marked, started_on])
  const sessionClassName = useMemo(
    () =>
      className(
        "session-calendar-day",
        short ? "-month-view" : "-week-view",
        status && `-${status}`,
        !isSameMonth && "-not-current-month",
        isHighlighted && "-highlighted",
        htmlClass
      ),
    [htmlClass, isHighlighted, isSameMonth, short, status]
  )
  const dateClassName = useMemo(
    () => className("session-calendar-date", day.isSame(moment(), "day") && "-current"),
    [day]
  )

  return (
    <SessionNode className={sessionClassName} onClick={onClick}>
      {isMarked && <div className="session-calendar-date-flag" />}
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-025">
          <div className={dateClassName}>{day.format("DD")}</div>
          {showIcon && <SessionHeaderIcon status={status} marked={isMarked} />}
        </div>
        <div className="session-calendar-day-time">{formatDateKeepZone(started_at, "hh:mm a")}</div>
      </div>
      <div className="d-flex flex-column flex-grow-1 justify-content-between">
        <SessionContent meetingFormat={meeting_format} duration={duration} status={status} />
        {!short && children}
      </div>
    </SessionNode>
  )
}

const SessionActionButton = ({ onClick, className: htmlClassName, children, dataTest }) => {
  const buttonClassName = useMemo(
    () => className("session-calendar-day_button icon-16-px", htmlClassName),
    [htmlClassName]
  )
  return (
    <button type="button" className={buttonClassName} onClick={onClick} data-test={dataTest}>
      {children}
    </button>
  )
}

const NodeTypePicker = forwardRef(function NodeTypePicker(props, ref) {
  return typeof props.onClick === "function" ? <button {...props} ref={ref} /> : <div {...props} ref={ref} />
})

const SessionStatusPicker = ({ session, hasAction, onChange }) => {
  const wrapperRef = useRef(null)
  const pickerRef = useRef(null)
  const listRef = useRef(null)
  const [open, setOpen] = useState(false)
  const availableActions = useMemo(() => {
    const actions = []
    if (hasAction(SessionActions.Pend))
      actions.push({
        status: SessionStatuses.Pending,
        action: SessionActions.Pend,
      })
    if (hasAction(SessionActions.Finish))
      actions.push({
        status: SessionStatuses.Finished,
        action: SessionActions.Finish,
      })
    if (hasAction(SessionActions.Cancel))
      actions.push(
        {
          status: SessionStatuses.Cancelled,
          action: SessionActions.Cancel,
        },
        {
          status: SessionStatuses.CancelledNoShow,
          action: SessionActions.Cancel,
        }
      )
    return actions
  }, [hasAction])

  const openMenu = () => {
    setOpen(true)
    setOverflow("hidden")
  }

  const setOverflow = useCallback(value => document.body.parentElement.style.setProperty("overflow", value), [])

  const closeMenu = useCallback(() => {
    setOpen(false)
    pickerRef.current?.focus()
    setOverflow(null)
  }, [setOverflow])

  const onClickOutside = useCallback(
    event => {
      if (open && !wrapperRef.current?.contains(event.target)) closeMenu()
    },
    [open, closeMenu]
  )

  const onEscape = useCallback(
    event => {
      if (open && event.keyCode === 27) closeMenu()
    },
    [open, closeMenu]
  )

  const onSelect = async (event, action, params) => {
    setOverflow(null)
    setOpen(false)
    onChange(event, action, params)
  }

  useEffect(() => {
    if (open && listRef.current) listRef.current?.querySelector("button")?.focus()
  }, [open])

  useEffect(() => {
    document.addEventListener("click", onClickOutside)
    document.addEventListener("keydown", onEscape)
    return () => {
      document.removeEventListener("click", onClickOutside)
      document.removeEventListener("keydown", onEscape)
    }
  }, [onClickOutside, onEscape])

  return (
    <div ref={wrapperRef} className="session-calendar-day_content-actions">
      <NodeTypePicker
        ref={pickerRef}
        onClick={availableActions.length > 0 ? openMenu : void 0}
        className="session-calendar-day_status gap-025"
      >
        <SessionHeaderIcon status={session.status} />
        <span className="session-calendar-day_status-text">{SessionTitles[session.status] || ""}</span>
      </NodeTypePicker>
      {open && (
        <ul ref={listRef} className="position-absolute card w-100 bottom mb-0 list-group">
          {availableActions.map(({ action, status }) => {
            return (
              <li key={status} className="list-group-item p-0">
                <button
                  type="button"
                  className={`btn-change-session-status -${status}`}
                  onClick={event => onSelect(event, action, { status })}
                >
                  {SessionTitles[status]}
                </button>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

const SessionActionsList = ({ session, actionHandlers }) => {
  const executeHandler = useCallback(
    (event, action, params) => {
      const handler = actionHandlers[action]
      if (handler) handler(event, { action, session, params })
    },
    [actionHandlers, session]
  )

  // In case when we Cancel and Destroy actions are exist
  // in pending Session it need do remove Destroy action,
  // because it will be able in dialog that will be open
  // on Cancel handler
  const actionsList = useMemo(() => {
    if (
      session.status === SessionStatuses.Pending &&
      checkActionExist(session.actions, SessionActions.Cancel) &&
      checkActionExist(session.actions, SessionActions.Destroy)
    ) {
      return session.actions.filter(action => action !== SessionActions.Destroy)
    }
    return session.actions
  }, [session.actions, session.status])

  const hasAction = useCallback(
    checkedAction =>
      checkActionExist(actionsList, checkedAction) && typeof actionHandlers[checkedAction] === "function",
    [actionsList, actionHandlers]
  )

  const getActionHandler = actionName => event => {
    event.stopPropagation()
    executeHandler(event, actionName)
  }

  switch (session.status) {
    case SessionStatuses.Pending: {
      return (
        <div className="session-calendar-day_content-actions">
          {hasAction(SessionActions.Finish) && (
            <SessionActionButton
              className="-submit -primary"
              onClick={getActionHandler(SessionActions.Finish)}
              dataTest="finish-session"
            >
              <Check />
            </SessionActionButton>
          )}
          {hasAction(SessionActions.Cancel) && (
            <SessionActionButton className="-secondary" onClick={getActionHandler(SessionActions.Cancel)}>
              <Cross />
            </SessionActionButton>
          )}
          {hasAction(SessionActions.Destroy) && (
            <SessionActionButton className="-secondary" onClick={getActionHandler(SessionActions.Destroy)}>
              <Cross />
            </SessionActionButton>
          )}
        </div>
      )
    }
    default:
      return <SessionStatusPicker session={session} hasAction={hasAction} onChange={executeHandler} />
  }
}

export const SessionTile = ({ session, actionHandlers = {}, className, onClick }) => {
  return (
    <Session session={session} className={className} onClick={onClick}>
      <SessionActionsList session={session} actionHandlers={actionHandlers} />
    </Session>
  )
}
