import { map } from "lodash"
import moment from "moment"
import React, { forwardRef, useCallback, useEffect, useState } from "react"
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import ReactDOM from "react-dom"

import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import listPlugin from "@fullcalendar/list"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import { ReactComponent as EmptyList } from "../../assets/images/icon_empty_calendar.svg"
import { INTERVIEW_CALENDAR_MAX_TIME, INTERVIEW_CALENDAR_MIN_TIME, MEDIA_MD_BREAKPOINT } from "../../common/constants"
import { formatDateKeepZone } from "../../helpers/dates"
import ScheduleSessionButton from "./ScheduleSessionButton"

const transformToDate = date => formatDateKeepZone(date, "YYYY-MM-DDTHH:mm:ss")

const PLUGINS = [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin]

const VIEW = Object.freeze({
  MOBILE: "listWeek",
  DESKTOP: "dayGridWeek",
})

const columnHeaderHtml = date => {
  return `<div class="fc-header-text">
      <span class="fc-header-day-number">${formatDateKeepZone(date, "DD")}</span>
      <span class="fc-header-day-title">${formatDateKeepZone(date, "dddd")}</span>
    </div>`
}

const EventContent = ({ event }) => {
  const { extendedProps = {}, title, start, end } = event
  const { is_overdue: isOverdue } = extendedProps
  return (
    <OverlayTrigger overlay={<Tooltip>{title}</Tooltip>}>
      <div className="fc-content">
        <div className="fc-time">{`${formatDateKeepZone(start, "hh:mma")}${
          end ? ` – ${formatDateKeepZone(end, "hh:mma")}` : ""
        }`}</div>
        <div className="fc-title">
          {title}
          {isOverdue && <span className="overdue-status"> Overdue</span>}
        </div>
      </div>
    </OverlayTrigger>
  )
}

const eventNewDiv = ({ event, el }) => {
  // Creating `div` to replace the default <a href=""/> for event
  const eventDiv = document.createElement("div")
  // Get classes on the default `a.fc-timeline-event`
  const classes = Array.from(el.classList)
  // Add classes to the new `div`
  eventDiv.classList.add(...classes)

  ReactDOM.render(<EventContent event={event} />, eventDiv)

  return eventDiv
}

const Schedule = forwardRef(function Schedule({ events, defaultDay, ...params }, ref) {
  const onSelectEvent = ({ event }) => {
    if (event.extendedProps.link) {
      window.open(event.extendedProps.link, "_blank")
    }
  }

  return (
    <FullCalendar
      ref={ref}
      plugins={PLUGINS}
      header={false}
      allDaySlot={false}
      navLinks={false}
      events={events}
      eventClick={onSelectEvent}
      defaultDate={defaultDay}
      minTime={INTERVIEW_CALENDAR_MAX_TIME}
      maxTime={INTERVIEW_CALENDAR_MIN_TIME}
      eventRender={eventNewDiv}
      height="auto"
      {...params}
    />
  )
})

export const DesktopWeekSchedule = ({ title, loading, events, week, setWeek, disableScheduleSessionButton }) => {
  const showNextWeek = () => {
    const nextWeek = moment.parseZone(week).add(1, "week").toDate()
    setWeek(nextWeek)
  }
  const showPreviousWeek = () => {
    const prevWeek = moment.parseZone(week).subtract(1, "week").toDate()
    setWeek(prevWeek)
  }
  const showCurrentWeek = () => {
    const currentWeek = moment.parseZone(new Date()).toDate()
    setWeek(currentWeek)
  }
  return (
    <>
      <Row className="mb-3 align-items-center justify-between w-100">
        <Col as="h3">{title}</Col>
        <div className="d-flex flex-shrink-0">
          <div className="d-flex mr-2 align-items-center">
            <button className="calendar-left-arrow mr-2" onClick={showPreviousWeek} />
            <button className="calendar-right-arrow" onClick={showNextWeek} />
          </div>
          <button className="btn btn-warning-light btn-sm mr-2" onClick={showCurrentWeek}>
            Current
          </button>
          {!disableScheduleSessionButton && <ScheduleSessionButton />}
        </div>
      </Row>
      <Row className="panel dashboard_schedule -desktop">
        {loading ? (
          <div className="dashboard_schedule_loader">
            <div className="spinner-wrapper">
              <div className="spinner-border text-primary" />
            </div>
          </div>
        ) : (
          <>
            <Schedule
              defaultDay={week}
              events={events}
              defaultView={VIEW.DESKTOP}
              columnHeaderHtml={columnHeaderHtml}
              slotDuration="0:30:00"
            />
            {events.length === 0 && (
              <div className="dashboard_schedule_empty dashboard_empty_list">
                <EmptyList />
                <div className="dashboard_empty_list-text mt-4">
                  <span className="dashboard_empty_list-text -bold">You don’t have any sessions this week</span>
                  <span>Check the opportunity below.</span>
                </div>
              </div>
            )}
          </>
        )}
      </Row>
    </>
  )
}

const MobileSchedule = ({ title, events, week, calendarPath, disableScheduleSessionButton }) => {
  return (
    <div className="mb-3 align-items-center">
      <Row className="mb-3">
        <Col as="h3" className="px-4">
          {title}
        </Col>
      </Row>
      {!disableScheduleSessionButton && (
        <Row className="pb-2 pt-2 px-4 bg-white">
          <Col>
            <ScheduleSessionButton />
          </Col>
        </Row>
      )}
      <Row className="panel dashboard_schedule -mobile mb-4">
        <Schedule
          defaultDay={week}
          events={events}
          defaultView={VIEW.MOBILE}
          listDayAltFormat={{
            day: "2-digit",
            month: "2-digit",
          }}
        />
        <a className="text-center font-weight-medium py-1" href={calendarPath}>
          View calendar
        </a>
      </Row>
    </div>
  )
}

const WeekSchedule = ({ title, loading, events: list, week, setWeek, calendarPath, disableScheduleSessionButton }) => {
  const [view, setView] = useState(VIEW.DESKTOP)
  const handler = useCallback(() => {
    const newView = document.body.offsetWidth < MEDIA_MD_BREAKPOINT ? VIEW.MOBILE : VIEW.DESKTOP
    if (view !== newView) setView(newView)
  }, [view])

  const events = map(list, event => {
    const eventTypeClass = event.view === "interview" ? "-interview" : "-session"
    const eventOrganizationClass = event.organization_id ? "-with-organization" : "-no-organization"
    return {
      ...event,
      start: transformToDate(event.start),
      end: transformToDate(event.end),
      overlap: false,
      classNames: ["max-width-100", eventTypeClass, eventOrganizationClass],
      extendedProps: {
        link: event.link,
        is_overdue: event.is_overdue,
      },
    }
  })

  useEffect(() => {
    handler()
    window.addEventListener("resize", handler)
    return () => {
      window.removeEventListener("resize", handler)
    }
  }, [handler])

  return view === VIEW.MOBILE ? (
    <MobileSchedule
      title={title}
      events={events}
      week={week}
      calendarPath={calendarPath}
      disableScheduleSessionButton={disableScheduleSessionButton}
    />
  ) : (
    <DesktopWeekSchedule
      title={title}
      events={events}
      week={week}
      setWeek={setWeek}
      loading={loading}
      disableScheduleSessionButton={disableScheduleSessionButton}
    />
  )
}
export default WeekSchedule
