import React, { useContext } from "react"
import { DataContext } from "../../../contexts"
import { ReactComponent as AlarmIcon } from "../../../assets/images/alarm.svg"
//import { ReactComponent as CelebrationIcon } from "../../../assets/images/celebration.svg"

export const UserDashboardContent = () => {
  const { dashboard } = useContext(DataContext)
  if (!dashboard || dashboard.overdue.count === 0) return null

  return (
    <div className="panel">
      <div className="user-dashboard__info-block">
        <AlarmIcon />
        <div>
          You have <a href={dashboard.overdue.path}>{dashboard.overdue.count} overdue sessions</a>
        </div>
        {/*<CelebrationIcon />*/}
        {/*<div>You have a new group</div>*/}
      </div>
    </div>
  )
}
