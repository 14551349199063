import React from "react"
import { DomainProvider } from "../../../providers"
import { useApiInitialPath } from "../../../hooks/useApiPath"
import useBaseHandlersBuilder from "../../../hooks/useBaseHandlersBuilder"
import { opening } from "../configurations/domain"
import { getSchoolsAutocomplete, getSubjectAutocomplete } from "../../../domain"

const OpeningPageProviders = ({ initial, children }) => {
  return (
    <DomainProvider
      initialStorageState={initial}
      config={opening}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      {children}
    </DomainProvider>
  )
}

export const OpeningAutocompleteProvider = ({ initial, children }) => {
  return (
    <DomainProvider
      initialStorageState={initial}
      connectToParentStore
      config={[getSubjectAutocomplete(true), getSchoolsAutocomplete(true)]}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      {children}
    </DomainProvider>
  )
}

export default OpeningPageProviders
