import { useCallback } from "react"
import { Methods } from "../constants"

export const keyBuilder = ({ entities, action, method, alias }) =>
  [method, ...entities, action, alias].filter(Boolean).join("_").toUpperCase()

export const useKeyBuilder = () => {
  const normalize = useNormalizeConfiguration()
  return useCallback(params => keyBuilder(normalize(params)), [normalize])
}

export const normalizeConfiguration = ({ entities = [], action = null, method = Methods.Get, alias = "" }) => ({
  entities,
  action,
  method,
  alias,
})

export const useNormalizeConfiguration = () => useCallback(params => normalizeConfiguration(params), [])
