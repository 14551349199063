import React, { useMemo } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { routerOptions } from "./constants"
import { getNavigationConfig } from "./configurations/routing"

const ScheduleModule = ({ currentUser, directUploadUrl }) => {
  const config = useMemo(
    () => getNavigationConfig({ role: currentUser.role, directUploadUrl, currentUser }),
    [currentUser, directUploadUrl]
  )
  const router = useMemo(() => createBrowserRouter(config, routerOptions), [config])
  return <RouterProvider router={router} />
}

export default ScheduleModule
