import React, { useCallback, useContext, useMemo } from "react"
import { Table as BTable, Col, Form, Row } from "react-bootstrap"
import { DynamicCell } from "./dynamicCell"
import { Sort } from "../../constants"
import { TableSelectionContext, TableStateContext } from "../../contexts"
import useViewport from "../../hooks/useViewport"
import { ReactComponent as CanSort } from "../../assets/images/sort"
import { ReactComponent as Sorted } from "../../assets/images/sorted"
import { className } from "../../helpers/className"
import { TableSelectionProvider, TableStateProvider } from "./providers"
import { defaultPerPage, defaultPage, defaultRowsPerPageOptions } from "./constants"
import noop from "../../helpers/noop"
import { buildId } from "../../helpers/forms"

const CheckBox = ({ disabled, checked, onChange, label }) => {
  const id = useMemo(buildId, [])

  return (
    <Form.Check
      custom
      id={id}
      disabled={disabled}
      className="v-2"
      checked={checked}
      onChange={onChange}
      label={label}
    />
  )
}

const TableFooter = ({
  columnsCount,
  disabled,
  count = 0,
  page = defaultPage,
  rowsPerPage = defaultPerPage,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const { isPhoneViewport } = useViewport()
  const maxSwitchers = useMemo(() => (isPhoneViewport ? 1 : 5), [isPhoneViewport])
  const halfSwitchers = useMemo(() => Math.floor(maxSwitchers / 2), [maxSwitchers])
  const pagesCount = useMemo(() => Math.ceil(count / rowsPerPage), [count, rowsPerPage])
  const pages = useMemo(() => {
    const list = Array(pagesCount)
      .fill(1)
      .map((el, idx) => el + idx)
    let start = 0
    if (pagesCount > maxSwitchers) {
      start = page - halfSwitchers
      if (start < 0) start = 0
      if (start > pagesCount - maxSwitchers) start = pagesCount - maxSwitchers
    }
    let end = page + halfSwitchers > pagesCount ? pagesCount : page + halfSwitchers
    if (end < maxSwitchers) end = maxSwitchers
    if (end > list.length) end = list.length
    return list.slice(start, end)
  }, [pagesCount, maxSwitchers, page, halfSwitchers])

  const options = useMemo(() => {
    let list = [...rowsPerPageOptions]
    if (!list.includes(rowsPerPage)) list.push(rowsPerPage)
    return list.sort((a, b) => a - b)
  }, [rowsPerPage, rowsPerPageOptions])
  return (
    <tfoot className="position-relative">
      <tr>
        <td colSpan={columnsCount} style={{ borderTopWidth: 0 }}>
          <Row className="align-items-center position-sticky" style={{ bottom: 0, backgroundColor: "#fff" }}>
            <Col sm={24} lg={2}>
              <select
                className="form-control select"
                value={rowsPerPage}
                disabled={disabled}
                onChange={({ target: { value } }) => onRowsPerPageChange(parseInt(value, 10))}
              >
                {options.map(count => (
                  <option key={count} value={count}>
                    {count}
                  </option>
                ))}
              </select>
            </Col>
            <Col sm={24} lg={22}>
              <nav className="pagy-bootstrap-nav pagination" role="navigation" aria-label="pager">
                <ul className="pagination mb-0" style={{ marginRight: isPhoneViewport ? 0 : "8.33333%" }}>
                  {pagesCount > halfSwitchers + 1 && (
                    <li className={className("page-item", "prev", (disabled || page - 1 <= 0) && "disabled")}>
                      <button
                        className="page-link font-weight-bolder"
                        tabIndex={disabled || page - 1 <= 0 ? -1 : 0}
                        disabled={disabled || page - 1 <= 0}
                        onClick={() => onPageChange(page - 1)}
                        dangerouslySetInnerHTML={{ __html: "‹&nbsp;Prev" }}
                      />
                    </li>
                  )}
                  {pagesCount > maxSwitchers && page > halfSwitchers + 1 && (
                    <li className={className("page-item", (disabled || page - 1 <= 0) && "disabled")}>
                      <button
                        className="page-link font-weight-bolder"
                        disabled={disabled || page === 1}
                        onClick={() => onPageChange(1)}
                      >
                        1
                      </button>
                    </li>
                  )}
                  {!isPhoneViewport && pagesCount > maxSwitchers && page > halfSwitchers + 2 && (
                    <li className="page-item disabled gap">
                      <button className="page-link font-weight-bolder" tabIndex="-1" disabled>
                        ...
                      </button>
                    </li>
                  )}
                  {pages.map(el => (
                    <li key={el} className={className("page-item", disabled && "disabled", page === el && "active")}>
                      <button
                        className="page-link font-weight-bolder"
                        disabled={disabled}
                        onClick={() => onPageChange(el)}
                      >
                        {el}
                      </button>
                    </li>
                  ))}
                  {!isPhoneViewport && pagesCount > maxSwitchers && pagesCount - halfSwitchers - 1 > page && (
                    <li className="page-item disabled gap">
                      <button className="page-link font-weight-bolder" tabIndex="-1" disabled>
                        ...
                      </button>
                    </li>
                  )}
                  {pagesCount > maxSwitchers && pagesCount - halfSwitchers > page && (
                    <li className={className("page-item", (disabled || page === pagesCount <= 0) && "disabled")}>
                      <button
                        className="page-link font-weight-bolder"
                        disabled={disabled || page === pagesCount}
                        onClick={() => onPageChange(pagesCount)}
                      >
                        {pagesCount}
                      </button>
                    </li>
                  )}
                  {pagesCount > halfSwitchers + 1 && (
                    <li className={className("page-item", "prev", (disabled || page + 1 > pagesCount) && "disabled")}>
                      <button
                        className="page-link font-weight-bolder"
                        tabIndex={disabled || page + 1 > pagesCount ? -1 : 0}
                        disabled={disabled || page + 1 > pagesCount}
                        onClick={() => onPageChange(page + 1)}
                        dangerouslySetInnerHTML={{ __html: "Next&nbsp;›" }}
                      />
                    </li>
                  )}
                </ul>
              </nav>
            </Col>
          </Row>
        </td>
      </tr>
    </tfoot>
  )
}

const HeaderCellWrapper = ({ sortable, align, field, sort, onSortChange, children }) => {
  const wrapperClass = ["d-flex", "flex-nowrap", "align-items-center"]
  if (align === "right") wrapperClass.push("flex-row-reverse")
  if (sortable) {
    wrapperClass.push("btn-clear")
    return (
      <button
        className={className(...wrapperClass)}
        onClick={() => onSortChange(typeof sort === "boolean" ? field : sort)}
      >
        {children}
      </button>
    )
  }
  return <div className={className(...wrapperClass)}> {children}</div>
}

const HeaderCellContent = ({
  column,
  sortDirection,
  sortColumn,
  onSortChange,
  isMobileView,
  columnsLength,
  classes,
  columnIndex = 0,
}) => {
  const { headerName, type, cellProps, sort, field } = column
  let align = "left"
  if (!isMobileView) {
    align = type === "string" || type === "phone" || type === "email" ? "left" : "right"
    if (columnIndex === columnsLength - 1) align = "right"
    if (columnIndex === 0) align = "left"
    if (type === "custom") align = cellProps?.align || "left"
  }
  const sortable = Boolean(sort)

  return (
    <HeaderCellWrapper
      sortable={sortable}
      align={align}
      field={field}
      sort={sort}
      onSortChange={onSortChange}
      classes={classes}
    >
      {align === "left" && <span>{headerName}</span>}
      {sortable && (
        <div className={className("icon-12-px", "ml-2", sortDirection === Sort.ASC && "rotate-180-deg")}>
          {(typeof sort === "boolean" ? field === sortColumn : sort === sortColumn) ? <Sorted /> : <CanSort />}
        </div>
      )}
      {align === "right" && <span>{headerName}</span>}
    </HeaderCellWrapper>
  )
}

const TableHead = ({
  loading,
  columns,
  sortColumn,
  sortDirection,
  onSortChange,
  headerCellClasses,
  checkSelection,
  selectable,
  onSelect,
}) => {
  return (
    <thead>
      <tr>
        {selectable && (
          <th className="brain-trust-table_select-all">
            <CheckBox disabled={loading} checked={checkSelection()} onChange={onSelect} />
          </th>
        )}
        {columns.map((column, idx) => {
          return (
            <th key={idx} className={headerCellClasses}>
              <HeaderCellContent
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                column={column}
                columnsLength={columns.length}
                columnIndex={idx}
                onSortChange={onSortChange}
              />
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

// todo - need to rename and move it to separated file
const useGetHandlers = handlersMap => {
  return (config, data) => {
    const handlers = handlersMap.get(config) || {}
    return Object.keys(handlers).reduce((acc, key) => {
      const handler = handlers[key]
      acc[key] = typeof handler === "function" ? (...params) => handler(...params, data) : handler
      return acc
    }, {})
  }
}

const DesktopTableBody = ({
  loading,
  columns,
  handlersMap,
  data,
  noResultText,
  selectable,
  checkSelection,
  onSelect,
}) => {
  const getHandlers = useGetHandlers(handlersMap)
  if (loading) {
    return (
      <tbody>
        <tr>
          <td colSpan={selectable ? columns.length + 1 : columns.length} align="center">
            <div className="mt-4">
              <div className="spinner-border text-primary" />
            </div>
          </td>
        </tr>
      </tbody>
    )
  } else if (data.length === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan={columns.length} align="center">
            <span>{noResultText}</span>
          </td>
        </tr>
      </tbody>
    )
  } else {
    return (
      <tbody>
        {data.map((row, idx) => (
          <tr key={idx}>
            {selectable && (
              <td className="brain-trust-table_select">
                <CheckBox checked={checkSelection(row)} onChange={e => onSelect(e, row)} />
              </td>
            )}
            {columns.map((column, idx) => (
              <DynamicCell
                {...column}
                handlers={getHandlers(column, row)}
                key={`${column.field}-${idx}`}
                row={row}
                first={idx === 0}
                last={idx === columns.length - 1}
              />
            ))}
          </tr>
        ))}
      </tbody>
    )
  }
}

const MobileTableBody = ({
  loading,
  columns,
  data,
  handlersMap,
  noResultText,
  sortColumn,
  sortDirection,
  onSortChange,
  headerCellClasses,
  selectable,
  checkSelection,
  onSelect,
}) => {
  const getHandlers = useGetHandlers(handlersMap)
  if (loading) {
    return (
      <tbody>
        <tr>
          <td colSpan={selectable ? columns.length + 1 : columns.length} align="center">
            <div className="mt-4">
              <div className="spinner-border text-primary" />
            </div>
          </td>
        </tr>
      </tbody>
    )
  } else if (data.length === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan={columns.length} align="center">
            <span>{noResultText}</span>
          </td>
        </tr>
      </tbody>
    )
  } else {
    return (
      <tbody>
        {selectable && (
          <tr>
            <td className="brain-trust-table_select-all" style={{ borderTopWidth: 0 }}>
              <div className="py-2">
                <CheckBox checked={checkSelection(...data)} onChange={e => onSelect(e, ...data)} label="Select all" />
              </div>
            </td>
          </tr>
        )}
        {data.map((row, idx) => {
          return (
            <tr key={idx}>
              <td className="brain-trust-table_select" style={{ borderTopWidth: 0 }}>
                {selectable && (
                  <div className="py-2">
                    <CheckBox label="Select" checked={checkSelection(row)} onChange={e => onSelect(e, row)} />
                  </div>
                )}
                <div className="card mx-n3 px-3">
                  <BTable aria-label="table" className="mb-0">
                    <tbody>
                      {columns.map((column, idx) => {
                        return (
                          <tr key={`${column.field}-${idx}`}>
                            <td width="35%" style={{ borderTopWidth: idx === 0 ? 0 : 1, fontWeight: 700 }}>
                              <HeaderCellContent
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                column={column}
                                columnsLength={columns.length}
                                columnIndex={idx}
                                onSortChange={onSortChange}
                                isMobileView
                                classes={headerCellClasses}
                              />
                            </td>
                            <DynamicCell
                              {...column}
                              cellProps={column.cellProps ? { ...column.cellProps, align: "left " } : void 0}
                              handlers={getHandlers(column, row)}
                              style={idx === 0 ? { borderTopWidth: 0 } : {}}
                              row={row}
                              first
                            />
                          </tr>
                        )
                      })}
                    </tbody>
                  </BTable>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    )
  }
}

export const StatelessTable = ({
  data = [],
  loading = false,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  sort: sortColumn,
  sortDirection,
  columns,
  count,
  className: tableClassName,
  onPageChange,
  onRowsPerPageChange,
  onSortChange,
  noResultText = "No Result",
  hideFooter,
  headerCellClasses,
  handlersMap = new Map(),
  selectable,
  checkSelection,
  onSelect,
}) => {
  const { isPhoneViewport } = useViewport()
  return (
    <BTable aria-label="table" className={className("brain-trust-table mb-0", tableClassName)}>
      {!isPhoneViewport && (
        <TableHead
          loading={loading}
          columns={columns}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSortChange={onSortChange}
          headerCellClasses={headerCellClasses}
          selectable={selectable}
          checkSelection={() => checkSelection(...data)}
          onSelect={e => onSelect(e, ...data)}
        />
      )}
      {isPhoneViewport ? (
        <MobileTableBody
          loading={loading}
          columns={columns}
          data={data}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSortChange={onSortChange}
          noResultText={noResultText}
          headerCellClasses={headerCellClasses}
          handlersMap={handlersMap}
          selectable={selectable}
          checkSelection={checkSelection}
          onSelect={onSelect}
        />
      ) : (
        <DesktopTableBody
          loading={loading}
          columns={columns}
          data={data}
          noResultText={noResultText}
          handlersMap={handlersMap}
          selectable={selectable}
          checkSelection={checkSelection}
          onSelect={onSelect}
        />
      )}
      {!hideFooter && data.length > 0 && (
        <TableFooter
          columnsCount={columns.length}
          disabled={loading}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </BTable>
  )
}

export const StatefulTableContent = ({
  data = [],
  loading = false,
  columns,
  count,
  onPageChange: localPageChange,
  onPerPageChange: localPerPageChange,
  onSortChange: localOnSortChange,
  noResultText,
  hideFooter,
  headerCellClasses,
  handlersMap,
  className,
  rowsPerPageOptions,
}) => {
  const { sortColumn, sortDirection, page, rowsPerPage, onPageChange, onPerPageChange, onSortChange } =
    useContext(TableStateContext)

  const { selectable, onSelect = noop, checkSelection = noop } = useContext(TableSelectionContext)

  const handleChangePage = useCallback(
    (...attrs) => {
      onPageChange(...attrs)
      if (localPageChange) localPageChange(...attrs)
    },
    [localPageChange, onPageChange]
  )

  const handlePerPageChange = useCallback(
    (...attrs) => {
      onPerPageChange(...attrs)
      if (localPerPageChange) localPerPageChange(...attrs)
    },
    [localPerPageChange, onPerPageChange]
  )

  const handleSortChange = useCallback(
    (...attrs) => {
      onSortChange(...attrs)
      if (localOnSortChange) localOnSortChange(...attrs)
    },
    [localOnSortChange, onSortChange]
  )

  return (
    <StatelessTable
      data={data}
      columns={columns}
      count={count}
      loading={loading}
      noResultText={noResultText}
      hideFooter={hideFooter}
      sort={sortColumn}
      page={page}
      className={className}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      sortDirection={sortDirection}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handlePerPageChange}
      onSortChange={handleSortChange}
      headerCellClasses={headerCellClasses}
      handlersMap={handlersMap}
      selectable={selectable}
      checkSelection={checkSelection}
      onSelect={onSelect}
    />
  )
}

export const StatefulTable = ({
  data,
  name,
  columns,
  count,
  loading,
  noResultText,
  sortColumn,
  hideFooter,
  headerCellClasses,
  handlersMap,
  initialPage,
  initialPerPage,
  rowsPerPageOptions,
  selectable,
  initialSelect,
  inheritState,
  onSelect,
  checkSelection,
  selectionDataMapper,
  resetSelectionOnChange,
  className,
  useStorage,
  onPageChange,
  onPerPageChange,
  onSortChange,
  inheritSelection = true,
}) => {
  return (
    <TableSelectionProvider
      selectable={selectable}
      initial={initialSelect}
      onSelect={onSelect}
      checkSelection={checkSelection}
      resetSelectionOnChange={resetSelectionOnChange}
      selectionDataMapper={selectionDataMapper}
      inherit={inheritSelection}
    >
      <TableStateProvider
        name={name}
        initialPage={initialPage}
        initialPerPage={initialPerPage}
        useStorage={useStorage}
        inherit={inheritState}
      >
        <StatefulTableContent
          data={data}
          count={count}
          columns={columns}
          loading={loading}
          rowsPerPageOptions={rowsPerPageOptions}
          noResultText={noResultText}
          sort={sortColumn}
          hideFooter={hideFooter}
          headerCellClasses={headerCellClasses}
          handlersMap={handlersMap}
          className={className}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
          onPerPageChange={onPerPageChange}
        />
      </TableStateProvider>
    </TableSelectionProvider>
  )
}
