import React from "react"
import moment from "moment"
import { Navigate } from "react-router-dom"
import Navigation from "../navigation"
import Schedule from "../schedule"
import Report from "../report"
import NewSchedulePage from "../schedule/new"
import { ReportNavigation } from "../navigation/report_navigation"
import { Students } from "../students"
import { Tutors } from "../tutors"

const root = "/"

export const RouteNames = Object.freeze({
  Schedules: "",
  Students: "students",
  Tutors: "tutors",
  Reports: "reports",
})

export const RoutesTitleNames = Object.freeze({
  [RouteNames.Schedules]: "Schedule",
  [RouteNames.Reports]: "Report",
  [RouteNames.Students]: "Students",
  [RouteNames.Tutors]: "Tutors",
})

export const RoutesPatterns = Object.freeze({
  [RouteNames.Schedules]: "/:scheduleId",
  [RouteNames.Reports]: `/:scheduleId/${RouteNames.Reports}/:report_date`,
  [RouteNames.Tutors]: `/:scheduleId/${RouteNames.Tutors}`,
  [RouteNames.Students]: `/:scheduleId/${RouteNames.Students}`,
})

const currentDate = moment().startOf(new Date()).format("YYYY-MM-DD")

export const getNavigationConfig = initial => [
  {
    path: root,
    element: <Navigation initial={initial} />,
    children: [
      {
        path: ":scheduleId",
        children: [
          {
            path: RouteNames.Schedules,
            element: <Schedule />,
          },
          {
            path: RouteNames.Students,
            element: <Students />,
          },
          {
            path: RouteNames.Reports,
            element: <ReportNavigation />,
            children: [
              {
                path: "",
                element: <Navigate to={currentDate} />,
              },
              {
                path: ":report_date",
                element: <Report />,
              },
              {
                path: "*",
                element: <Navigate to={currentDate} />,
              },
            ],
          },
          {
            path: RouteNames.Tutors,
            element: <Tutors />,
          },
        ],
      },
    ],
  },
  {
    path: "new",
    element: <NewSchedulePage />,
  },
]

export const tabsNavigationConfig = Object.values(RouteNames).map(path => ({
  path,
  pattern: RoutesPatterns[path],
  title: RoutesTitleNames[path],
}))
