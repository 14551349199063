import { createContext } from "react"
import { propertyGetter } from "../helpers/validators"
import { request } from "../common/apiClient"
import { noop } from "../helpers/noop"

export const ValidationContext = createContext({
  propertyGetter,
  getValidationValueByName: () => false,
})

export const DataContext = createContext({})
export const DataContextActions = createContext({
  resetFields: noop,
  updateState: noop,
  getState: noop,
  setPartialState: noop,
})
export const InitialLoadingContext = createContext({})
export const ApiContext = createContext({ base: "api" })
export const QueryContextState = createContext({ current: null })
export const QueryContext = createContext({ getQueryState: noop })
export const ConfigurationContext = createContext({ common: [], initial: [], current: [] })
export const RequestsParamsCacheContext = createContext({ getCache: () => null, setCache: noop })
export const TransportContext = createContext(request)
export const ScheduleContext = createContext({
  short: false,
  showIcon: false,
  readOnly: false,
  month: void 0,
  highlighted: new Set(),
  marked: new Set(),
})
export const ContextMenuContext = createContext({
  isContextMenuOpen: false,
  openContextMenu: noop,
  closeContextMenu: noop,
})
export const ConfirmationModalContext = createContext({
  isConfirmationMenuOpen: false,
  openConfirmationModal: noop,
  closeConfirmationModal: noop,
})

export const DomainTransactionContext = createContext({})

export const QueryParamsContextActions = createContext({
  setParams: () => {},
  removeParams: () => {},
  resetParams: () => {},
})
export const TableStateContext = createContext({
  initial: true,
  state: {},
  sortColumn: void 0,
  sortDirection: null,
  onPageChange: () => {},
  onPerPageChange: () => {},
  onSortChange: () => {},
})

export const TableSelectionContext = createContext({})
