import React, { useContext, useEffect } from "react"
import { OpeningPageWrapper } from "../../components"
import { Link } from "react-router-dom"
import { StatefulTable, TableStateProvider } from "../../../table"
import { openingsList } from "../../configurations/domain"
import { useApiInitialPath } from "../../../../hooks/useApiPath"
import useBaseHandlersBuilder from "../../../../hooks/useBaseHandlersBuilder"
import { DomainProvider } from "../../../../providers"
import { DataContext, QueryContext } from "../../../../contexts"
import { useColumns } from "./columns"
import { OpeningAutocompleteProvider } from "../../providers/opening_page"
import { Filters } from "./filters"
import { FieldNames } from "./constants"
import { useDataMemoryState } from "../../../../hooks/useMemoryState"

const OpeningsPageContent = () => {
  const { openings = [], openingsCount, openingsListFilters, tableState } = useContext(DataContext)
  const { getQueryState } = useContext(QueryContext)
  const { loading, request } = getQueryState(openingsList)
  const columns = useColumns()

  useEffect(() => {
    if (!request) return
    let school_id
    let show_closed
    const { page, rowsPerPage: results_per_page } = tableState || {}

    if (!page || !results_per_page) return
    if (openingsListFilters) {
      school_id = openingsListFilters[FieldNames.School]
        ? parseInt(openingsListFilters[FieldNames.School]?.value)
        : undefined
      show_closed = openingsListFilters[FieldNames.ShowClosed]
    }

    request({ params: { page, results_per_page, school_id, show_closed } })
  }, [openingsListFilters, tableState, request])

  return (
    <OpeningPageWrapper
      title="All openings"
      titleAffix={
        <Link to="new" className="btn btn-outline-primary" data-test="new-opening">
          Create opening
        </Link>
      }
    >
      <div className="panel_header mx-n4 mt-n5 mb-4">
        <nav className="tabs-nav">
          <a className="tabs-nav_item -active" data-turbolinks="false" href="/openings" data-test="openings">
            Openings
          </a>
          <a className="tabs-nav_item" data-turbolinks="false" href="/admin/schools" data-test="schools">
            Schools
          </a>
          <a className="tabs-nav_item" data-turbolinks="false" href="/admin/opening_groups">
            Groups
          </a>
        </nav>
      </div>
      <Filters />
      <StatefulTable
        inheritState
        data={openings}
        columns={columns}
        count={openingsCount}
        loading={loading}
        headerCellClasses="text-gray-chat font-weight-medium font-size-small"
      />
    </OpeningPageWrapper>
  )
}

export const OpeningsPage = ({ initial }) => {
  return (
    <DomainProvider
      initialStorageState={initial}
      config={[openingsList]}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      <OpeningAutocompleteProvider>
        <TableStateProvider useStorage={useDataMemoryState}>
          <OpeningsPageContent />
        </TableStateProvider>
      </OpeningAutocompleteProvider>
    </DomainProvider>
  )
}
