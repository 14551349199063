import React, { useContext } from "react"
import { OpeningForm } from "../../forms/opening_form"
import { OpeningAutocompleteProvider } from "../../providers/opening_page"
import { OpeningPageWrapper } from "../../components"
import { QueryContext } from "../../../../contexts"
import { createOpening } from "../../configurations/domain"
import { mapFormDataToBackend } from "../../forms/opening_form/helpers"
import { useNavigate } from "react-router-dom"
import { useApiInitialPath } from "../../../../hooks/useApiPath"
import useBaseHandlersBuilder from "../../../../hooks/useBaseHandlersBuilder"
import { DomainProvider } from "../../../../providers"

const CreateOpeningPageContent = () => {
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(createOpening)
  const navigate = useNavigate()

  const onSubmit = data => {
    request({
      data: { opening: mapFormDataToBackend(data) },
      onSuccess: ({ id }) => navigate(`/${id}`),
    })
  }

  return (
    <OpeningPageWrapper title="Create opening">
      <OpeningForm onSubmit={onSubmit} loading={loading} />
    </OpeningPageWrapper>
  )
}

export const CreateOpeningPage = () => {
  return (
    <DomainProvider
      config={[createOpening]}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      <OpeningAutocompleteProvider>
        <CreateOpeningPageContent />
      </OpeningAutocompleteProvider>
    </DomainProvider>
  )
}
